
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import * as api from '../../../store/apiClient';

import { clickHandler } from '../../../utils/util';
import Pagination from '../../global/pagination';
import ApiError from '../../global/apiError';
import Loading from '../../global/loading';
import { monthNameAbbreviations } from '../../../utils/extensions';

interface CampaignCompetitorsProps {
    campaignId: string;
    acceptedMarketing: boolean;
    close: () => void;
    logout: () => void;
}

interface CampaignCompetitorsState {
    pageNumber: number;
    pageSize: number;
    search: string;
    totalCustomerCount: number;
    maxPageNumber: number;
    customers: CampaignCustomer[];
    loading: boolean;
    loadError: api.ApiError | null;
}

interface CampaignCustomer {
    id: string;
    firstname: string;
    lastname: string;
    emailAddress: string;
    age: number | null;
    birthDay: number;
    birthMonth: number;
}

interface GetCampaignCustomersResponse {
    totalCustomerCount: number;
    maxPageNumber: number;
    customers: CampaignCustomer[];
}

class CampaignCompetitors extends React.Component<CampaignCompetitorsProps, CampaignCompetitorsState> {

    searchTimeout: NodeJS.Timeout | null;

    constructor(props: CampaignCompetitorsProps) {
        super(props);

        this.searchTimeout = null;

        this.state = {
            pageNumber: 1,
            pageSize: 25,
            search: '',
            totalCustomerCount: 0,
            maxPageNumber: 0,
            customers: [],
            loading: false,
            loadError: null
        }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    loadCustomers = () => {
        const { campaignId, acceptedMarketing } = this.props;
        const { pageNumber, pageSize, search } = this.state;

        if (this.searchTimeout) clearTimeout(this.searchTimeout);

        this.setState({ loading: true });

        api.getWithAuth<GetCampaignCustomersResponse>(`api/v1/campaign/${campaignId}/customers?acceptedMarketing=${acceptedMarketing}&pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}`, this.props.logout)
            .subscribe(
                resp => this.setState({ loading: false, loadError: null, customers: resp.customers, totalCustomerCount: resp.totalCustomerCount, maxPageNumber: resp.maxPageNumber }),
                err => this.setState({ loading: false, loadError: err  }))
    }

    componentDidMount() {
        this.loadCustomers();
    }

    clearFilter = () => this.setState({ search: '' }, this.onSearchChanged)

    searchChanged = (val: string) => this.setState({ search: val }, this.onSearchChanged)

    onSearchChanged = () => {
        if (this.searchTimeout) clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => this.loadCustomers(), 800);
    }

    setPage = (pageNumber: number) => this.setState({ pageNumber: pageNumber }, this.loadCustomers);

    setPageSize = (pageSize: number) => this.setState({ pageSize: pageSize }, this.loadCustomers);


    render() {
        const { t } = this.context;
        const { close } = this.props;
        const { search, customers, maxPageNumber, pageNumber, pageSize, loading, loadError } = this.state;

        return <div>
            {/* header */}
            <div className="alert alert-info">
                <div className='row'>
                    <div className='col-xs-9'>
                        <ct.TextBox id='name' labelKey='CampaignCompetitors:searchLabel' placeholderKey='CampaignCompetitors:searchLabel' value={ct.asFormValue('search', search)} callback={this.searchChanged} />
                    </div>
                    <div className='col-xs-3'>
                        <div className='section-actions right' style={({ marginTop: '35px' })}>
                            <button className='btn btn-warning' onClick={e => clickHandler(e, this.clearFilter)}>
                                {t('Global:clear')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* body */}
            <div>
                {loadError
                    ? <ApiError error={loadError} />
                    : loading ? <Loading />
                        : <>
                            <table className='table table-condensed'>
                                <tbody>
                                    {customers.map(c => <tr key={c.id}><td>{c.firstname}</td><td>{c.lastname}</td><td>{c.emailAddress}</td><td>{c.age}</td><td>{c.birthDay > 0 && c.birthMonth > 0 ? `${c.birthDay} ${t(monthNameAbbreviations[c.birthMonth-1])}` : null}</td></tr>)}
                                </tbody>
                            </table>
                            <Pagination maxPage={maxPageNumber} pageSize={pageSize} pageNumber={pageNumber} setPage={this.setPage} setPageSize={this.setPageSize} />
                        </>
                }
            </div>

            {/* footer */}
            <div className='btn-toolbar'>
                <button className='btn btn-basic' onClick={e => clickHandler(e, close)}>{t('Global:close')}</button>
            </div>
        </div>
    }
}

export default CampaignCompetitors;