import { ValidationError, Time } from '../../global/types';
import * as api from '../../apiClient';
import { OpeningTimes } from '../diary/types';

export interface RegistrationSettings {
    clientName: string;
    venueId: string;
    venueCountryId: number;
    venueName: string;
    culture: string;
    kioskId: string;
    kioskName: string;
    clientImageId: string | null;
    registrationImageId: string | null;
    registrationImageUrl: string | null;
    registrationFields: RegistrationField;
    requiredRegistrationFields: RegistrationField;
    termsAndConditionsId: string;
    termsAndConditions: string;
    counterSignatoryTermsId: string;
    counterSignatoryTerms: string;
    finalInstructions: string;
    confirmTermsText: string;
    confirmCounterSignatoryTermsText: string;
    separateCounterSignatoryTermsRequired: boolean;
    resultsMarketingText: string;
    generalMarketingText: string;
    minAgeWarningThreshold: number | null;
    minAgeForSelfRegistration: number | null;
    minAge: number | null;
    welcomeScreenImageIds: string[];
    welcomeScreenImageUrls: string[];
    welcomeText: string;
    nameStepHeading: string;
    addressStepHeading: string;
    returningCustomerStepHeading: string;
    contactPreferencesStepHeading: string;
    welcomeImageInterval: number;
    requireSignatures: boolean;
    requireLatestTerms: boolean;
    dateFormat: DateFormat;
    timeFormat: TimeFormat;
}

export enum CloseTimeBehaviour {
    StartBeforeCloseTime = 0,
    EndBeforeCloseTime = 1
}

// Keep in sync with  Alpha.Cloud.Api.Models.RegistrationField
export enum RegistrationField {
    None = 0,
    FirstName = 1 << 0,
    LastName = 1 << 1,
    DateOfBirth = 1 << 2,
    YearOfBirth = 1 << 3,
    BasicAddress = 1 << 4,
    FullAddress = 1 << 5,
    Postcode = 1 << 6,
    EmailAddress = 1 << 7,
    PhoneNumber = 1 << 8,
    EmergencyContactName = 1 << 9,
    EmergencyContactNumber = 1 << 10,
    Nickname = 1 << 11,
    Gender = 1 << 12
}

export enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}

export enum TimeFormat {
    TwentyFourHour = 0,
    TwelveHour = 1
}

export enum DateFormat {
    DMY = 0,
    MDY = 1
}

export enum ProductTaxMode {
    IncludingTax = 0,
    ExcludingTax = 1
}


export interface Venue {
    id: string;
    name: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    addressLine4: string;
    town: string;
    county: string;
    countryId: number;
    postalCode: string;
    emailAddress: string;
    sendEmailsFromAddress: string;
    sendEmailsFromName: string;
    phoneNumber: string;
    websiteAddress: string;
    mondayOpenTime: Time;
    mondayCloseTime: Time;
    tuesdayOpenTime: Time;
    tuesdayCloseTime: Time;
    wednesdayOpenTime: Time;
    wednesdayCloseTime: Time;
    thursdayOpenTime: Time;
    thursdayCloseTime: Time;
    fridayOpenTime: Time;
    fridayCloseTime: Time;
    saturdayOpenTime: Time;
    saturdayCloseTime: Time;
    sundayOpenTime: Time;
    sundayCloseTime: Time;
    archived: boolean;
    salesTaxNumber: string;
    culture: string;
    timeZoneId: number;
    currencyId: number;
    currencySymbol: string;
    firstDayOfWeek: DayOfWeek;
    publicWebsites: VenuePublicWebsite[];
    registrationConfigured: boolean;
    minAgeWarningThreshold: number | null;
    minAgeForSelfRegistration: number | null;
    minAge: number | null;
    lightLogoImageId: string | null;
    lightLogoImageUrl: string | null;
    darkLogoImageId: string | null;
    darkLogoImageUrl: string | null;
    webBookingExpiry: number;
    webBookingFailedPaymentTimeout: number | null;
    webBookingIncompletePaymentTimeout: number | null;
    webBookingMinTimeFromEventStart: Time;
    lastUpdated: number;
    registrationKiosks: VenueRegistrationKiosk[];
    integrationTokens: VenueIntegrationToken[];
    hasResources: boolean;
    requireLatestTerms: boolean;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    enableMultipleCategoriesPerCustomer: boolean;
    closeTimeBehaviour: CloseTimeBehaviour;
    productTaxMode: ProductTaxMode;
}

export interface VenuePublicWebsite {
    id: number;
    name: string;
    resourceIds: string[];
    baseUrl: string;
}

export interface VenueRegistrationKiosk {
    registrationKioskId: string | null;
    kioskName: string;
    registrationImageId: string | null;
    registrationCode: string | null;
    registrationFields: number;
    requiredRegistrationFields: number;
    finalInstructions: string;
    resultsMarketingText: string;
    generalMarketingText: string;
    requireSignatures: boolean;
    enablePhotos: boolean;
    welcomeImageInterval: number;
    welcomeScreenImageIds: string[];
    welcomeScreenImageUrls: string[];
    welcomeText: string;
    nameStepHeading: string;
    addressStepHeading: string;
    returningCustomerStepHeading: string;
    contactPreferencesStepHeading: string;
    registrationTermsId: string;
    requireAddressForChildren: boolean;
    createDateTime: Date;
    lastUpdated: Date | null;
}

export enum IntegrationType {
    Timing = 1,
    TransactionalEmail = 2,
    ViewPointVideo = 3
}

export interface VenueIntegrationToken {
    id: string;
    integrationId: string;
    integrationType: IntegrationType,
    token: string | null;
}

export const isOpen = (openTime: Time, closeTime: Time): boolean  => openTime.getHours() > 0 || openTime.getMinutes() > 0 || openTime.getSeconds() > 0 || closeTime.getHours() > 0 || closeTime.getMinutes() > 0 || closeTime.getSeconds() > 0;

export interface VenueState {
    isActive: boolean;
    isLoading: boolean;
    venues: Venue[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    selectedVenueId: string;
    validationErrors: ValidationError[];
    selectedTabKey: string;
    dateFormat: DateFormat;
    timeFormat: TimeFormat;
}


export enum VenueActionTypes {
    RequestVenues = 'REQUEST_VENUE',
    ReceivedVenues = 'RECEIVE_VENUE',
    SaveVenue = 'SAVE_VENUE',
    VenueSaved = 'VENUE_SAVED',
    VenueSaveFailed = 'VENUE_SAVE_FAILED',
    EditVenue = 'EDIT_VENUE',
    SaveRegistrationSettings = 'SAVE_REGISTRATION_SETTINGS',
    SelectVenue = 'SELECT_VENUE',
    SelectTab = 'SELECT_TAB',
    EnableIntegration = "ENABLE_INTEGRATION",
    RevokeIntegrationToken = "REVOKE_INTEGRATION_TOKEN",
}

export interface LoadVenues {
    type: VenueActionTypes.RequestVenues;
}


export interface ReceiveVenues {
    type: VenueActionTypes.ReceivedVenues;
    venues: Venue[];
    error: api.ApiError | null;
}

export interface SaveVenue {
    type: VenueActionTypes.SaveVenue;
    isNew: boolean;
    venueId?: string;
    venue: Venue;
    lightLogoImg: File | null;
    darkLogoImg: File | null;
}

export interface VenueSaved {
    type: VenueActionTypes.VenueSaved;
    venueId: string;
}

export interface VenueSaveFailed {
    type: VenueActionTypes.VenueSaveFailed;
    error: api.ApiError;
}

export interface EditVenue {
    type: VenueActionTypes.EditVenue
}

export interface SelectVenue {
    type: VenueActionTypes.SelectVenue;
    venueId: string;
}

export interface SaveRegistrationSettings {
    type: VenueActionTypes.SaveRegistrationSettings;
    registrationKioskId: string | null;
    venueId: string;
    kioskName: string;
    registrationImage: File | null;
    registrationPassword: string | null;
    registrationFields: number;
    requiredRegistrationFields: number;
    existingWelcomeImages: string[];
    newWelcomeImages: File[];
    welcomeText: string;
    welcomeImageInterval: number;
    nameStepHeading: string;
    addressStepHeading: string;
    returningCustomerStepHeading: string;
    contactPreferencesStepHeading: string;
    finalInstructions: string;
    resultsMarketingText: string;
    generalMarketingText: string;
    requireSignatures: boolean;
    registrationTermsId: string;
    requireAddressForChildren: boolean;
}

export interface SelectTab {
    type: VenueActionTypes.SelectTab;
    tabKey: string;
}

export interface EnableIntegration {
    type: VenueActionTypes.EnableIntegration;
    venueId: string;
    integrationId: string;
}

export interface RevokeIntegrationToken {
    type: VenueActionTypes.RevokeIntegrationToken;
    venueId: string;
    integrationTokenId: string;
}

export const getOpenTime = (venue: Venue | null, openingTimes: OpeningTimes[], date: Date): Time | null => {

    var override = openingTimes.filter(t => t.date.getFullYear() === date.getFullYear() && t.date.getMonth() === date.getMonth() && t.date.getDate() === date.getDate());
    if (override.length > 0) {
        return override[0].open;
    }

    if (venue) {
        const day = date.getDay();
        switch (day) {
            case 0:
                return venue.sundayOpenTime;
            case 1:
                return venue.mondayOpenTime;
            case 2:
                return venue.tuesdayOpenTime;
            case 3:
                return venue.wednesdayOpenTime;
            case 4:
                return venue.thursdayOpenTime;
            case 5:
                return venue.fridayOpenTime;
            case 6:
                return venue.saturdayOpenTime;
            default:
                return null;
        }
    } else {
        return null;
    }
}


export const getOpenHour = (venue: Venue | null, openingTimes: OpeningTimes[], date: Date): number | null => {
    var ot = getOpenTime(venue, openingTimes, date);
    return ot ? ot.getHours() : null;
}

export const getCloseTime = (venue: Venue | null, openingTimes: OpeningTimes[], date: Date): Time => {

    var override = openingTimes.filter(t => t.date.getFullYear() === date.getFullYear() && t.date.getMonth() === date.getMonth() && t.date.getDate() === date.getDate());
    if (override.length > 0) {
        return override[0].close;
    }

    if (venue) {
        const day = date.getDay();
        switch (day) {
            case 0:
                return venue.sundayCloseTime;
            case 1:
                return venue.mondayCloseTime;
            case 2:
                return venue.tuesdayCloseTime;
            case 3:
                return venue.wednesdayCloseTime;
            case 4:
                return venue.thursdayCloseTime;
            case 5:
                return venue.fridayCloseTime;
            case 6:
                return venue.saturdayCloseTime;
            default:
                return Time.zero();
        }
    } else {
        return Time.zero();
    }
}

export const getCloseHour = (venue: Venue | null, openingTimes: OpeningTimes[], date: Date): number => getCloseTime(venue, openingTimes, date).getHours()