
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import Dropzone from 'react-dropzone';
import moment from 'moment';

import { Product, ProductPricePoint, ProductPricingMode, ProductTag, ProductType } from '../../../../store/pages/products/types';
import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';
import * as api from '../../../../store/apiClient';
import { ApplicationState } from '../../../../store';
import * as ProductActions from '../../../../store/pages/products/actions';
import * as ModalActions from '../../../../store/global/modal/actions';
import { ProductCategory } from '../../../../store/pages/productCategories/types';
import { TaxRate } from '../../../../store/pages/taxRates/types';
import { clickHandler, generateTempId, isNullOrEmpty } from '../../../../utils/util';
import ApiError from '../../../global/apiError';
import { ImageFile, getImageProps } from '../../../../utils/images';
import { Days, Time, ValidationError } from '../../../../store/global/types';
import { ActivityFormat } from '../../../../store/pages/activityFormats/types';
import StandardProductPrice from './standardProductPrice';
import TagSelection from '../../../global/tagSelection';
import { SpecialTagType, Tag } from '../../../../store/pages/tags/types';
import { hasPriceOverlap, validateFixedPricing, validatePricing, validatePricingAppliesOnPricePoint, validatePricingApplyPriceFrom, validatePricingApplyPriceTo, validatePricingArchived, validatePricingEffectiveDatesPricePoint, validatePricingEffectiveFrom, validatePricingEffectiveTo, validatePricingTagId, validatePricingUnitPrice } from './pricingValidation';
import ProductPriceOverrides from './productPriceOverrides';
import { ProdPrice, ProductFixedPricing } from './types';
import ProductPriceOverrideForm from './productPriceOverrideForm';
import { DateFormat, ProductTaxMode, TimeFormat, Venue } from '../../../../store/pages/venues/types';
import { isXMLDoc } from 'jquery';

interface MappedReduxState {
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
    venues: Venue[];
    taxRates: TaxRate[];
    productCategories: ProductCategory[];
    tags: Tag[];
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
}

interface LocalProps {
    isNew: boolean;
    venueId: string;
    product: Product | null;
    activityFormats: ActivityFormat[];
    close: () => void;
}

interface Actions {
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
    saveProduct: (isNew: boolean, productId: string | null, product: Product, productImg: File | null) => void;
}

type ProductFormProps = MappedReduxState & Actions & LocalProps;

interface ProductFormState {
    name: ct.FormValue<string>;
    description: ct.FormValue<string>;
    categoryIds: string[];
    taxRateId: ct.FormValue<string>;
    archived: ct.FormValue<boolean>;
    accountingCategory: ct.FormValue<string>;
    accountingDepartment: ct.FormValue<string>;
    nominalCode: ct.FormValue<string>;
    pricingMode: ct.FormValue<ProductPricingMode>;
    productImageUrl: string | null;
    productImage: ImageFile | null;
    imageError: string | null;
    validationError: string | null;
    productTags: ProductTag[];
    standardPriceEffectiveDatesPricePoint: ct.FormValue<ProductPricePoint>
    pricing: ProdPrice[];
    standardPricingError: string | null;
}

class ProductForm extends React.Component<ProductFormProps, ProductFormState> {

    constructor(props: ProductFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: ProductFormProps): ProductFormState {

        const { isNew, product } = props;

        const standardPricing = isNew || !product ? [] : product.pricing.filter(p => !p.isOverride);
        const standardPriceEffectiveDatesPricePoint = standardPricing.length > 0 ? standardPricing[0].effectiveDatesPricePoint : ProductPricePoint.EventOrPurchaseDate;
        const pricingMode = (isNew || !product) ? ProductPricingMode.PerUnit : product.pricingMode;

        return {
            name: this.validateName((isNew || !product) ? '' : product.name),
            description: this.validateDescription((isNew || !product) ? '' : product.description),
            categoryIds: (isNew || !product) ? [] : product.categoryIds,
            taxRateId: this.validateTaxRate((isNew || !product) ? '' : product.taxRateId),
            archived: this.validateArchived((isNew || !product) ? false : product.archived),
            accountingCategory: this.validateAccountingCategory((isNew || !product) ? '' : product.accountingCategory),
            accountingDepartment: this.validateAccountingDepartment((isNew || !product) ? '' : product.accountingDepartment),
            nominalCode: this.validateNominalCode((isNew || !product) ? '' : product.nominalCode),
            productImageUrl: (isNew || !product) ? null : product.productImageUrl,
            pricingMode: this.validatePricingMode(pricingMode),
            productTags: (isNew || !product) ? [] : product.tags.filter(t => !t.archived),
            pricing: (isNew || !product) ? [this.createDefaultPrice(false, standardPriceEffectiveDatesPricePoint, pricingMode === ProductPricingMode.Fixed)] : product.pricing.map(pp => ({
                key: pp.id,
                id: pp.id,
                isOverride: pp.isOverride,
                unitPrice: this.validatePricingUnitPrice(pp.unitPrice),
                fixedPricing: this.validatePricingFixedPricing(pp.fixedPricing.sort((p1, p2) => p1.minQuantity - p2.minQuantity).map(p => ({
                    minQuantity: p.minQuantity,
                    maxQuantity: p.maxQuantity,
                    price: p.price,
                    errorKey: null
                    }))),
                effectiveFrom: this.validatePricingEffectiveFrom(moment(pp.effectiveFrom)),
                effectiveTo: this.validatePricingEffectiveTo(pp.effectiveTo ? moment(pp.effectiveTo) : null),
                effectiveDatesPricePoint: this.validatePricingEffectiveDatesPricePoint(pp.effectiveDatesPricePoint),
                tagId: this.validatePricingTagId(pp.tagId),
                appliesOnPricePoint: this.validatePricingAppliesOnPricePoint(pp.appliesOnPricePoint),
                applyPriceOnDays: pp.applyPriceOnDays,
                applyPriceFrom: this.validatePricingApplyPriceFrom(pp.applyPriceFrom),
                applyPriceTo: this.validatePricingApplyPriceTo(pp.applyPriceTo),
                archived: this.validatePricingArchived(pp.archived),
            })),
            standardPriceEffectiveDatesPricePoint: this.validateStandardPriceEffectiveDatesPricePoint(standardPriceEffectiveDatesPricePoint),
            productImage: null,
            imageError: null,
            validationError: null,
            standardPricingError: null
        };
    }

    componentDidUpdate(prevProps: ProductFormProps) {
        // Only update state is resource has changed
        const { product: prevProduct, saveComplete: prevSaveComplete } = prevProps;
        const { product, saveComplete } = this.props;
        if ((!prevProduct && product) || (prevProduct && !product) || (prevProduct && product && prevProduct.id !== product.id)) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (saveComplete && !prevSaveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    private saveProduct = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    private close = () => this.props.close();

    private save = () => {
        if (!v.isValid(this.state)) {
            this.setState({ validationError: this.context.t('Global:formNotValid') });
        } else {
            const productId = this.props.isNew || !this.props.product ? null : this.props.product.id;
            const { isNew, venueId, saveProduct, activityFormats } = this.props;
            const { name, description, categoryIds, taxRateId, archived, accountingCategory, accountingDepartment, nominalCode, productImage, pricingMode, productTags, pricing } = this.state;

            if (pricingMode.value === ProductPricingMode.Fixed) {
                const fixedPricing = pricing.flatMap(p => p.fixedPricing);
                if (fixedPricing.length === 0 || fixedPricing.filter(p => !isNullOrEmpty(p.errorKey)).length > 0) {
                    this.setState({ validationError: this.context.t('Global:formNotValid') });
                    return;
                }
            }

            if (pricing.length === 0) {
                this.setState({ validationError: this.context.t('ProductForm:productMustHaveAPrice') });
                return;
            }

            if (pricing.filter(p => !v.isValid(p)).length > 0) {
                this.setState({ validationError: this.context.t('Global:formNotValid') });
                return;
            }

            // check for overlapping prices
            const pricingValidation = validatePricing(pricing.filter(p => !p.isOverride && !p.archived.value));
            if (!isNullOrEmpty(pricingValidation)) {
                this.setState({ standardPricingError: this.context.t(pricingValidation) });
                return;
            }

            const fixedPricingValidation = validateFixedPricing(pricingMode.value, pricing);
            if (!isNullOrEmpty(fixedPricingValidation)) {
                this.setState({ standardPricingError: this.context.t(fixedPricingValidation) });
                return;
            }

            if (archived.value) {
                const linkedActivityFormats = activityFormats.filter(af => !af.archived && af.products.findIndex(p => p.productId === productId) >= 0);
                if (linkedActivityFormats.length > 0) {
                    this.setState({ validationError: this.context.t('ProductForm:cannotArchiveAsLinkedRoActivityFormats', { linkedActivityFormats: linkedActivityFormats.map(af => af.name).join(',') })});
                    return;
                }
            }

            saveProduct(isNew,
                productId,
                {
                    id: productId || '',
                    venueId: venueId,
                    type: ProductType.Standard,
                    name: name.value,
                    description: description.value,
                    categoryIds: categoryIds,
                    taxRateId: taxRateId.value,
                    archived: archived.value,
                    accountingCategory: accountingCategory.value,
                    accountingDepartment: accountingDepartment.value,
                    nominalCode: nominalCode.value,
                    isActivityProduct: false,
                    taxRate: 0,
                    taxRateName: '',
                    productImageUrl: null,
                    pricingMode: pricingMode.value,
                    pricing: pricing.map(p => ({
                        id: p.id || '',
                        isOverride: p.isOverride,
                        unitPrice: p.unitPrice.value || 0,
                        fixedPricing: p.fixedPricing.map(fp => ({ minQuantity: fp.minQuantity, maxQuantity: fp.maxQuantity, price: fp.price })),
                        effectiveFrom: p.effectiveFrom.value.toDate(),
                        effectiveTo: p.effectiveTo.value ? p.effectiveTo.value.toDate() : null,
                        effectiveDatesPricePoint: p.effectiveDatesPricePoint.value,
                        tagId: p.tagId.value,
                        appliesOnPricePoint: p.appliesOnPricePoint.value,
                        applyPriceOnDays: p.applyPriceOnDays,
                        applyPriceFrom: p.applyPriceFrom.value,
                        applyPriceTo: p.applyPriceTo.value,
                        archived: p.archived.value,
                    })),
                    tags: productTags.filter(t => !t.archived),
                    voucherRedemptionAmount: null,
                }, productImage ? productImage.file : null);

            this.setState({ validationError: null, standardPricingError: null });
        }
    }

    

    categoryChanged = (categoryId: string, selected: boolean) => {

        let categories = this.state.categoryIds.filter(c => c !== categoryId);
        if (selected) {
            categories = categories.concat([categoryId]);
        }

        this.setState({ categoryIds: categories });
    }

    taxRateChanged = (newValue: string) => {
        this.setState({ taxRateId: this.validateTaxRate(newValue) });
    }

    validateName = (val: string) => v.validate(val, 'name', [v.required], this.props.validationErrors);
    validateDescription = (val: string) => v.validate(val, 'description', [v.required], this.props.validationErrors);
    validateCategory = (val: string) => v.validate(val, 'category', [v.required], this.props.validationErrors);
    validateUnitPrice = (val: number) => v.validate(val, 'unitPrice', [v.numeric, v.required], this.props.validationErrors);
    validateTaxRate = (val: string) => v.validate(val, 'taxRate', [v.required], this.props.validationErrors);
    validateArchived = (val: boolean) => v.validate(val, 'archived', [], this.props.validationErrors);
    validateAccountingCategory = (val: string) => v.validate(val, 'accountingCategory', [], this.props.validationErrors);
    validateAccountingDepartment = (val: string) => v.validate(val, 'accountingDepartment', [], this.props.validationErrors);
    validateNominalCode = (val: string) => v.validate(val, 'nominalCode', [], this.props.validationErrors);
    validatePricingMode = (val: ProductPricingMode) => v.validate(val, 'pricingMode', [], this.props.validationErrors);

    validateStandardPriceEffectiveDatesPricePoint = (val: ProductPricePoint) => v.validate(val, 'standardPriceEffectiveDatesPricePoint', [], this.props.validationErrors);
    validatePricingUnitPrice = (val: number | null) => validatePricingUnitPrice(val, this.props.validationErrors);
    validatePricingEffectiveFrom = (val: moment.Moment) => validatePricingEffectiveFrom(val, this.props.validationErrors);
    validatePricingEffectiveTo = (val: moment.Moment | null) => validatePricingEffectiveTo(val, this.props.validationErrors);
    validatePricingEffectiveDatesPricePoint = (val: ProductPricePoint) => validatePricingEffectiveDatesPricePoint(val, this.props.validationErrors);
    validatePricingTagId = (val: string | null) => validatePricingTagId(val, this.props.validationErrors);
    validatePricingAppliesOnPricePoint = (val: ProductPricePoint) => validatePricingAppliesOnPricePoint(val, this.props.validationErrors);
    validatePricingApplyPriceFrom = (val: Time | null) => validatePricingApplyPriceFrom(val, this.props.validationErrors);
    validatePricingApplyPriceTo = (val: Time | null) => validatePricingApplyPriceTo(val, this.props.validationErrors);
    validatePricingArchived = (val: boolean) => validatePricingArchived(val, this.props.validationErrors);

    validatePricingFixedPricing = (fixedPricing: ProductFixedPricing[]) => {
        return fixedPricing.reduce<ProductFixedPricing[]>((result, p) => {
            let errorKey: string | null = null;

            if (p.maxQuantity < p.minQuantity) {
                errorKey = 'ProductForm:maxMustBeGreaterThanMin';
            } else if (p.price === 0) {
                errorKey = 'ProductForm:priceMustBeGreaterThanZero';
            }

            if (result.length > 0) {
                const prev = result[result.length - 1];
                if (p.minQuantity <= prev.maxQuantity) {
                    errorKey = 'ProductForm:quantitiesCannotOverlap'
                }
            }

            result.push({ ...p, errorKey: errorKey });
            return result;
        }, []);
    }

    onPricingModeChanged = (val: string) => {
        const intVal = parseInt(val);
        this.setState(s => ({
            pricingMode: this.validatePricingMode(intVal),
            pricing: s.pricing.map(p => ({ ...p, fixedPricing: intVal === ProductPricingMode.Fixed && p.fixedPricing.length === 0 ? [{ minQuantity: 0, maxQuantity: 1, price: 0, errorKey: ''}] : p.fixedPricing }))
        }))
    }

    onImageDrop = (files: File[]) => {
        const { t } = this.context;
        const file = files[0];

        getImageProps(file).then(img => {
            if (img.width !== 160 || img.height !== 160) {
                this.setState({ imageError: t('Global:imageWrongSize', { w: 160, h: 160 }) });
            } else {
                this.setState({ imageError: null, productImage: { file: file, preview: URL.createObjectURL(file) } });
            }
        });
    }

    addTag = () => { }

    updateProductPrice = (productPrice: ProdPrice) => this.setState(s => ({ pricing: s.pricing.map(pp => pp.key === productPrice.key ? productPrice : pp) }))

    standardPriceEffectiveDatesPricePointChanged = (val: string) => {
        const pricePointVal = parseInt(val);
        if (ProductPricePoint[pricePointVal] === undefined) return;

        this.setState(s => ({
            standardPriceEffectiveDatesPricePoint: this.validateStandardPriceEffectiveDatesPricePoint(pricePointVal),
            pricing: s.pricing.map(p => p.isOverride ? p : { ...p, effectiveDatesPricePoint: this.validatePricingEffectiveDatesPricePoint(pricePointVal)})
        }));
    }

    effectiveFromChanged = (key: string, newDate: moment.Moment) => this.setState(s => {
        const newPricing = s.pricing.map(pp => pp.key === key ? { ...pp, effectiveFrom: this.validatePricingEffectiveFrom(newDate) } : pp);
        const standardPricingError = validatePricing(newPricing.filter(p => !p.isOverride && !p.archived.value));
        return { pricing: newPricing, standardPricingError: standardPricingError };
    })
    effectiveToChanged = (key: string, newDate: moment.Moment | null) => this.setState(s => {
        const newPricing = s.pricing.map(pp => pp.key === key ? { ...pp, effectiveTo: this.validatePricingEffectiveTo(newDate) } : pp);
        const standardPricingError = validatePricing(newPricing.filter(p => !p.isOverride && !p.archived.value));
        return { pricing: newPricing, standardPricingError: standardPricingError };
    })
    effectivePricePointChanged = (key: string, pricePoint: ProductPricePoint) => this.setState(s => ({ pricing: s.pricing.map(pp => pp.key === key ? { ...pp, effectiveDatesPricePoint: this.validatePricingEffectiveDatesPricePoint(pricePoint) } : pp) }))
    unitPriceChanged = (key: string, unitPrice: number) => this.setState(s => ({ pricing: s.pricing.map(pp => pp.key === key ? { ...pp, unitPrice: this.validatePricingUnitPrice(unitPrice) } : pp) }))
    appliesOnPricePointChanged = (key: string, pricePoint: ProductPricePoint) => this.setState(s => ({ pricing: s.pricing.map(pp => pp.key === key ? { ...pp, appliesOnPricePoint: this.validatePricingAppliesOnPricePoint(pricePoint) } : pp) }))
    appliesOnDayOfWeekChanged = (key: string, newDays: Days) => this.setState(s => ({ pricing: s.pricing.map(pp => pp.key === key ? { ...pp, applyPriceOnDays: newDays } : pp) }))
    applyPriceFromChanged = (key: string, time: Time) => this.setState(s => ({ pricing: s.pricing.map(pp => pp.key === key ? { ...pp, applyPriceFrom: this.validatePricingApplyPriceFrom(time) } : pp) }))
    applyPriceToChanged = (key: string, time: Time) => this.setState(s => ({ pricing: s.pricing.map(pp => pp.key === key ? { ...pp, applyPriceTo: this.validatePricingApplyPriceTo(time) } : pp) }))
    priceTagChanged = (key: string, tagId: string | null) => this.setState(s => ({ pricing: s.pricing.map(pp => pp.key === key ? { ...pp, tagId: this.validatePricingTagId(tagId) } : pp) }))
    removePrice = (key: string) => this.setState(s => ({ pricing: s.pricing.filter(pp => pp.key !== key) }))
    fixedPricingChanged = (key: string, fixedPricing: ProductFixedPricing[]) => this.setState(s => ({ pricing: s.pricing.map(pp => pp.key === key ? { ...pp, fixedPricing: fixedPricing } : pp) }))

    createDefaultPrice = (isOverride: boolean, pricePoint: ProductPricePoint, isFixedPrice: boolean) => ({
        key: generateTempId(),
        id: '',
        isOverride: isOverride,
        unitPrice: this.validatePricingUnitPrice(null),
        fixedPricing: isFixedPrice ? [{ minQuantity: 1, maxQuantity: 1, price: 0, errorKey: null }] : [],
        effectiveFrom: this.validatePricingEffectiveFrom(moment(new Date())),
        effectiveTo: this.validatePricingEffectiveTo(null),
        effectiveDatesPricePoint: this.validatePricingEffectiveDatesPricePoint(pricePoint),
        tagId: this.validatePricingTagId(null),
        appliesOnPricePoint: this.validatePricingAppliesOnPricePoint(pricePoint),
        applyPriceOnDays: Days.None,
        applyPriceFrom: this.validatePricingApplyPriceFrom(null),
        applyPriceTo: this.validatePricingApplyPriceTo(null),
        archived: this.validatePricingArchived(false)
    })

    addPrice = (isOverride: boolean) => {
        const newPrice = this.createDefaultPrice(isOverride, this.state.standardPriceEffectiveDatesPricePoint.value, this.state.pricingMode.value === ProductPricingMode.Fixed);
        if (isOverride) {
            this.showEditPriceOverrideForm(newPrice)
        } else {
            this.setState(s => {
                const newPricing = s.pricing.concat(newPrice);
                const standardPricingError = hasPriceOverlap(newPricing.filter(p => !p.isOverride && !p.archived.value)) ? 'ProductForm:standardPricesCannotOverlap' : null;
                return { pricing: newPricing, standardPricingError: standardPricingError };
            })
        }
    }

    updatePrice = (key: string, newPricing: ProdPrice) => this.setState(s => {
        var isNew = s.pricing.findIndex(p => p.key === key) < 0;
        return { pricing: isNew ? s.pricing.concat(newPricing) : s.pricing.map(p => p.key === key ? newPricing : p) }
    }, this.props.closeModal)

    editPriceOverride = (key: string) => {
        const { pricing } = this.state;

        const price = pricing.find(p => p.key === key);
        if (price) {
            this.showEditPriceOverrideForm(price)
        }
    }

    showEditPriceOverrideForm = (price: ProdPrice) => {
        const { tags, closeModal, showModal, dateFormat, timeFormat } = this.props;
        const { pricingMode } = this.state;
        const isFixed = pricingMode.value === ProductPricingMode.Fixed;

        showModal(<ProductPriceOverrideForm
            overridePrice={price}
            isFixed={isFixed}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            tags={tags}
            saveChanges={this.updatePrice}
            removePrice={this.removePrice}
            close={closeModal} />, 'ProductPriceOverrideForm');
    }

    render() {
        let message: any;
        const { t } = this.context;
        const { name, description, taxRateId, archived, categoryIds, accountingCategory, accountingDepartment,
            nominalCode, productImageUrl, productImage, pricingMode, productTags, standardPriceEffectiveDatesPricePoint, pricing, imageError, validationError, standardPricingError } = this.state;
        const { venueId, saveError, saveComplete, productCategories, dateFormat, timeFormat, taxRates, tags, isNew } = this.props;

        if (saveError) {
            message = <ApiError error={saveError} />;
        } else if (validationError) {
            message = (<div className='alert alert-danger'>{validationError}</div>);
        }
        else if (saveComplete) {
            message = (<div className='bg-success'>{t('Global:saveComplete')}</div>);
        }

        const taxRateOptions = [{ key: '', name: t('ProductForm:selectTaxRate') }].concat(taxRates.map(c => ({ key: c.id, name: c.name })));

        const pricingModeOptions = Object.keys(ProductPricingMode).filter(key => typeof ProductPricingMode[key as any] === 'number').map(key => ({ key: ProductPricingMode[key as any].toString(), name: t(`ProductPricingMode:${key}`) }));

        let previewImg: JSX.Element | null = null;
        const imageUrl = productImage ? productImage.preview : productImageUrl !== null ? productImageUrl : null;
        if (imageUrl) {
            previewImg = <img src={imageUrl} className='file-preview' alt='preview' style={({ width: '100px' })}></img>;
        }

        const isFixed = pricingMode.value === ProductPricingMode.Fixed;
        const pricePointOptions = Object.keys(ProductPricePoint).filter(key => typeof ProductPricePoint[key as any] === 'number').map(key => ({ key: ProductPricePoint[key as any].toString(), name: t(`ProductPricePoint:${key}`) }));
        const nonPricingTags = tags.filter(t => productTags.findIndex(pt => pt.tagId === t.id) >= 0 && pricing.findIndex(p => p.tagId.value && p.tagId.value === t.id) < 0)

        const standardPrices = pricing.filter(p => !p.isOverride).sort((p1, p2) => p1.effectiveFrom.value.valueOf() - p2.effectiveFrom.value.valueOf());
        const overridePrices = pricing.filter(p => p.isOverride).sort((p1, p2) => p1.effectiveFrom.value.valueOf() - p2.effectiveFrom.value.valueOf());

        return <div className='productForm'>
            <h2 className='product_title'>{isNew ? t('ProductForm:addProduct') : name.value}</h2>

            <section className='form-panel'>
                <form className='data-form' onSubmit={this.saveProduct} autoComplete='off'>
                    <ct.TextBox id='name' labelKey='ProductForm:name' placeholderKey='ProductForm:name' value={name} callback={val => this.setState({ name: this.validateName(val) })} />

                    <ct.TextBox id='description' labelKey='Global:description' placeholderKey='Global:description' value={description} callback={val => this.setState({ description: this.validateDescription(val) })} />

                    <ct.Select id='taxRate' labelKey='ProductForm:taxRate' value={({ ...taxRateId, value: taxRateId.value.toString() })} callback={this.taxRateChanged} options={taxRateOptions} />

                    <ct.Select id='pricingMode' labelKey='ProductForm:pricingMode' value={({ ...pricingMode, value: pricingMode.value.toString() })} callback={this.onPricingModeChanged} options={pricingModeOptions} />

                    <label>{t('ProductForm:pricing')}</label>
                    {this.renderPricingMessage(venueId)}
                    <ct.Select id='standardPriceEffectiveDatesPricePoint' labelKey='ProductForm:standardPriceEffectiveDatesPricePoint' value={({ ...standardPriceEffectiveDatesPricePoint, value: standardPriceEffectiveDatesPricePoint.value.toString() })} callback={this.standardPriceEffectiveDatesPricePointChanged} options={pricePointOptions} />
                    <table style={{ margin: '15px 0' }}>
                        <thead>
                            <tr>
                                <th>{t('ProductForm:priceEffectiveFrom')}</th>
                                <th style={{ minWidth: '140px' }}>{t('ProductForm:priceEffectiveTo')}</th>
                                {isFixed ? <>
                                    <th>{t('ProductForm:fromQuantity')}</th>
                                    <th>{t('ProductForm:toQuantity')}</th>
                                </> : null
                                }
                                <th>{t('Global:price')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {standardPrices.map(p => <StandardProductPrice
                                key={p.key}
                                isFixed={isFixed}
                                canDelete={standardPrices.length > 1}
                                productPrice={p}
                                effectiveFromChanged={this.effectiveFromChanged}
                                effectiveToChanged={this.effectiveToChanged}
                                unitPriceChanged={this.unitPriceChanged}
                                removePrice={this.removePrice}
                                fixedPricingChanged={this.fixedPricingChanged}
                                ref={null} />)}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={4}>
                                    <button className='btn btn-primary' onClick={e => clickHandler(e, () => this.addPrice(false))}>{t('ProductForm:addPrice')}</button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>

                    {standardPricingError ? <div className='alert alert-danger'>{t(standardPricingError)}</div> : null}

                    <ProductPriceOverrides
                        overridePrices={overridePrices}
                        tags={tags}
                        isFixed={isFixed}
                        dateFormat={dateFormat}
                        timeFormat={timeFormat}
                        editPrice={this.editPriceOverride}
                        addPriceOverride={() => this.addPrice(true)}
                        removePrice={this.removePrice} />

                    <div className='row'>
                        <div className='col-xs-12'>
                            <label>{t('ProductForm:nonPricingTags')}</label>
                            <TagSelection selectedTags={nonPricingTags} specialTagType={SpecialTagType.None} canCreateTag={true} tagAdded={(tag: Tag) => this.setState(s => ({ productTags: s.productTags.concat({ productTagId: '', tagId: tag.id, name: tag.name, colour: tag.colour, archived: false }) }))} removeTag={(tagId: string) => this.setState(s => ({ productTags: s.productTags.filter(t => t.tagId !== tagId) }))} />
                        </div>
                    </div>

                    <ct.TextBox id='accountingCategory' labelKey='ProductForm:accountingCategory' placeholderKey='ProductForm:accountingCategory' value={accountingCategory} callback={val => this.setState({ accountingCategory: this.validateAccountingCategory(val) })} />

                    <ct.TextBox id='accountingDepartment' labelKey='ProductForm:accountingDepartment' placeholderKey='ProductForm:accountingDepartment' value={accountingDepartment} callback={val => this.setState({ accountingDepartment: this.validateAccountingDepartment(val) })} />

                    <ct.TextBox id='nominalCode' labelKey='ProductForm:nominalCode' placeholderKey='ProductForm:nominalCode' value={nominalCode} callback={val => this.setState({ nominalCode: this.validateNominalCode(val) })} />

                    <label>{t('ProductForm:productImage')}</label>
                    <div>
                        <Dropzone multiple={false} accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] }} onDrop={this.onImageDrop}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} className='file-drop'>
                                    <input {...getInputProps()} />
                                    <p>{t('Global:imageDropText')}</p>
                                </div>
                            )}
                        </Dropzone>
                        {previewImg}
                        {isNullOrEmpty(imageError) ? null : <div className='alert alert-danger'>{imageError}</div>}
                    </div>

                    <ct.Checkbox id='archived' labelKey='Global:archive' value={archived} callback={val => this.setState({ archived: this.validateArchived(val) })} />

                    {this.renderCategories(productCategories, categoryIds)}

                    {message}

                    <p />
                    <div className='btn-toolbar'>
                        <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button>
                        <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')}</button>
                    </div>
                </form>
            </section>
        </div>;
    }

    renderCategories(productCategories: ProductCategory[], categoryIds: string[]) {
        const { t } = this.context;
        const { venueId } = this.props;
        return (
            <div className='form-group'>
                <label>{t('ProductForm:categories')}</label>
                <table>
                    <tbody>
                        {productCategories.filter(c => c.venueId === venueId && !c.archived).map(c => (
                        <tr key={c.id}>
                            <td><input type='checkbox' id={c.id} checked={categoryIds.indexOf(c.id) >= 0} onChange={e => this.categoryChanged(c.id, e.currentTarget.checked)} /></td>
                            <td><label style={this.buildCategoryStyle(c)} htmlFor={c.id}>{c.name}</label></td>
                            <td style={({ paddingLeft: '10px' })}>{c.showOnPointOfSale ? <label className='label label-primary'>{t('Global:POS')}</label> : null}</td>
                            <td style={({ paddingLeft: '10px' })}>{c.showOnWebShop ? <label className='label label-primary'>{t('Global:WEBSHOP')}</label> : null}</td>
                            <td style={({ paddingLeft: '10px' })}>{c.reportingPriority}</td>
                        </tr>))}
                    </tbody>
                </table>
            </div>
        );
    }

    renderFixedPricing = (fixedPricing: ProductFixedPricing[]) => {
        return <div>TODO</div>
    }

    buildCategoryStyle(category: ProductCategory) {
        return { marginLeft: '5px', paddingLeft: '3px', borderLeft: `4px solid ${category.colour}` };
    }

    renderPricingMessage = (venueId: string) => {
        const { venues } = this.props;
        const { t } = this.context;
        const selectedVenue = venues.find(v => v.id === venueId);

        if (!selectedVenue) {
            return null;
        }

        if (selectedVenue.productTaxMode === ProductTaxMode.IncludingTax) {
            return <div className='alert alert-info'>{t('ProductForm:productPriceInclusiveOfTaxMsg')}</div>
        } else if (selectedVenue.productTaxMode === ProductTaxMode.ExcludingTax) {
            return <div className='alert alert-info'>{t('ProductForm:productPriceExcudingTaxMsg')}</div>
        } else {
            return <div>Unknown product tax mode {ProductTaxMode[selectedVenue.productTaxMode]}</div>
        }
    }
};


const mapStateToProps = (state: ApplicationState) => ({
    saveComplete: state.products.saveComplete,
    saveError: state.products.saveError,
    validationErrors: state.products.validationErrors,
    venues: state.venues.venues,
    taxRates: state.taxRates.taxRates,
    productCategories: state.productCategories.productCategories,
    tags: state.tags.tags.filter(t => !t.archived),
    timeFormat: state.venues.timeFormat,
    dateFormat: state.venues.dateFormat,

});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveProduct: bindActionCreators(ProductActions.actionCreators.saveProduct, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch)
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(ProductForm);
