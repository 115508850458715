
import { Time, ValidationError } from '../../global/types';
import {CustomerType, Gender, MarketingPreference, VenueDate } from '../customer/types';
import { TaskTrigger, TaskStateEnum, ParameterType } from '../tasks/types';
import * as api from '../../apiClient';
import { isNullOrEmpty } from '../../../utils/util';
import { CustomFieldType } from '../activityFormats/types';
import { Tag } from '../tags/types';
import { EventRegistrationCategory } from '../reception/types';
import { BookingCustomeField } from '../../../components/pages/diary/types';

export enum EventType {
    Public = 1,
    PrivateGroup = 2
}

export enum EventStatus {
    Unknown = 0,
    Provisional = 1,
    Confirmed = 2,
    Cancelled = 4
}

export enum ReservationType {
    NonExclusive = 1,
    Exclusive = 2
}

export enum WebBookingStatus {
    None = 0,
    Created = 1 << 0,
    ExistingCustomerAdded = 1 << 1,
    NewCustomerAdded = 1 << 2,
    PaymentStarted = 1 << 3,
    PaymentFailed = 1 << 4,
    PaymentComplete = 160,
    ManuallyCompleted = 192,
    Complete = 1 << 7
}

export interface ReservationMap {
    key: string;
    id: string;
}

export interface SaveEventResponse {
    eventId: string;
    event: Event;
    reservationMap: ReservationMap[];
}

export interface LoadEventResponse {
    event: Event;
}

export interface IScrollPosition {
    top: number;
    left: number;
}

export interface CustomerCategoryCount {
    categoryId: string;
    categoryName: string;
    count: number;
}

export interface ReservationLane {
    confirmed: number;
    unConfirmed: number;
    available: number;
}

export interface IReservation {
    id: string | null;
    eventId: string;
    reservationType: ReservationType;
    eventName: any;
    draft: boolean;
    resourceId: string;
    resourceConfigurationId: string | null;
    resourceConfigurationName: string | null;
    resourceConfigurationCode: string | null;
    resourceName: string;
    shortResourceName: string;
    activityFormatId: string;
    activityFormatVariationId: string;
    activityFormatVariationScheduleId: string;
    activityFormatVariationScheduleSequence: number;
    mustBookItemsTogether: boolean;
    primaryReservationId: string | null;
    isMultiScheduleReservation: boolean;
    startTime: Date;
    endTime: Date;
    actualStartTime?: Date | null;
    depositDueDate: Date | null;
    overduePaymentDate: Date | null;
    paymentDueDate: Date | null;
    outstandingDepositAmount: number;
    overdueAmount: number;
    outstandingAmount: number;
    eventStatus: EventStatus;
    colour: string;
    maxParticipants: number;
    bookedParticipants: CustomerCategoryCount[];
    confirmedParticipants: CustomerCategoryCount[];
    unconfirmedParticipants: CustomerCategoryCount[];
    registeredParticipants?: CustomerCategoryCount[];
    checkedInParticipants?: CustomerCategoryCount[];
    lanes: ReservationLane[];
    bookedCustomerIds: string[];
    notSelectable?: boolean;
    minGapBefore?: number;
    minGapAfter?: number;
    compatibleActivityFormatVariationIds: string[];
    flagged: boolean;
}

export interface DiaryReservation extends IReservation {
    key: string;
    archived: boolean;
    cancelled: boolean;
    deleted: boolean;
    isPlaceholder?: boolean;
    targetReservationId?: string | null;
    isSelected?: boolean;
    membershipTypeId: string | null;
    membershipTypeLabel: string | null;
    membershipTypeLabelColour: string | null;
    blockedResourceIds: string[];
}

export interface EventReservation {
    id: string | null;
    key: string;
    eventId: string;
    eventName: string;
    resourceId: string;
    resourceConfigurationId: string | null;
    activityFormatId: string;
    activityFormatVariationId: string;
    activityFormatVariationScheduleId: string;
    activityFormatVariationScheduleSequence: number;
    mustBookItemsTogether: boolean;
    primaryReservationId: string | null;
    isMultiScheduleReservation: boolean;
    startTime: Date;
    endTime: Date;
    maxParticipants: number;
    bookedParticipants: CustomerCategoryCount[];
    reservationType: ReservationType;
    colour: string;
    depositDueDate?: Date | null;
    overduePaymentDate?: Date | null;
    paymentDueDate?: Date | null;
    membershipTypeId: string | null;
    membershipTypeLabel: string | null;
    membershipTypeLabelColour: string | null;
    notes: string;
    archived: boolean;
}

export interface Event {
    id: string;
    name: string;
    type: EventType;
    startTime: Date;
    endTime: Date;
    draft: boolean;
    createdBy?: string;
    createDateTime?: Date;
    reservations: EventReservation[];
    bookings: EventBooking[];
    tasks: EventTask[];
    colour: string;
    totalBilled?: number;
    paymentsTotal?: number;
    paidAmount?: number;
    overdueAmount?: number;
    depositAmount?: number;
    outstandingDepositAmount?: number;
    archived: boolean;
    deleted: boolean;
    deletedByUserAccountId: string | null;
    deletedByUserName: string | null;
    deletedByUserIpAddress: string | null;
    whenDeleted: Date | null;
    cancelled: boolean;
    cancelledByUserAccountId: string | null;
    whenCancelled: Date | null;
    cancellationReason: string | null;
    cancelledByUserName: string | null;
    cancelledByUserIpAddress: string | null;
    resultsUrl: string | null;
    version: number;
    publicEventPageUrl: string;
}

export interface EventBookingReservation {
    id: string;
    startTime: Date;
    endTime: Date;
    resourceId: string;
    resourceConfigurationId: string;
    eventName: string;
    eventId: string;
    colour: string;
    reservationType: ReservationType;
    maxParticipants: number;
    bookedParticipants: CustomerCategoryCount[];
    activityFormatId: string;
    activityFormatVariationId: string;
    activityFormatVariationScheduleId: string;
    activityFormatVariationScheduleSequence: number;
    membershipTypeId: string | null;
    membershipTypeLabel: string | null;
    membershipTypeLabelColour: string | null;
    mustBookItemsTogether: boolean;
    primaryReservationId: string | null;
    isMultiScheduleReservation: boolean;
    notes: string;
}

export interface EventBooking {
    key: string;
    id: string;
    publicWebsiteId: number | null;
    billId?: string;
    billNumber?: string;
    isWebBooking: boolean;
    webBookingStatus: WebBookingStatus;
    webBookingComplete: boolean;
    cancelled: boolean;
    cancellationReason: string | null;
    cancelledDateTime: Date | null;
    cancelledBy: string | null;
    createDateTimeInLocalTime: Date;
    firstEventStartDateTime: Date;
    customer: BookingCustomer | null;
    registrationLink: string;
    bookingConfirmationClientEmailTemplateId: string | null;
    bookingConfirmationEmailId: string | null;
    emergencyContactName: string;
    emergencyContactNumber: string;
    customFieldDefinitions: BookingCustomeField[];
    customFields: CustomFieldValue[];
    reservations: EventBookingReservation[];
    notes: BookingNote[];
    flagged: boolean;
}

export interface BookingCustomer {
    key: string;
    customerId: string;
    customerType: CustomerType;
    firstname: string;
    lastname: string;
    nickname: string | null;
    companyName: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    addressLine4: string;
    town: string;
    county: string;
    countryId: number;
    postalCode: string;
    emailAddress: string;
    phoneNumber: string;
    gender: Gender;
    marketingPreference: MarketingPreference;
    resultsPreference: MarketingPreference;
    publicResultsConsent: boolean;
    emergencyContactName: string;
    emergencyContactNumber: string;
    hasActiveMembership: boolean;
    tags: Tag[];
    notes: string;
}

export interface ResourceOpeningTimes {
    resourceId: string;
    closed: boolean;
    message: string;
    open: Time;
    close: Time;
}

export interface OpeningTimes {
    date: Date;
    closed: boolean;
    message: string;
    open: Time;
    close: Time;
    resourceOverrides: ResourceOpeningTimes[];
}

export const getReservationStartTimeInMinutes = (r: IReservation, date: Date) => {
    const { startTime } = r;
    var day = date.getLocalEpocDay();
    var startDay = startTime.getLocalEpocDay();

    if (startDay < day) {
        return 0;
    } else {
        return startTime.getHours() * 60 + startTime.getMinutes()
    }
}

export const getReservationEndTimeInMinutes = (r: IReservation, date: Date) => {
    const { endTime } = r;
    var day = date.getLocalEpocDay();
    var endDay = endTime.getLocalEpocDay();

    if (endDay > day) {
        return (24 * 60);
    } else {
        return endTime.getHours() * 60 + endTime.getMinutes()
    }
} 

export interface BookingNote {
    id: string;
    text: string;
    createdBy: string;
    createDateTime: Date;
}

export interface DiaryNote {
    id: string;
    venueId: string;
    date: Date;
    text: string;
}

export enum AuditEntryType {
    BookingCreated = 1,
    BillCreated = 2,
    BillItemCreated = 3,
    ReservationCreated = 4,
    ReservationStartTimeChanged = 5,
    ReservationDurationChanged = 6,
    ReservationActivityChanged = 7,
    ReservationMaxParticipantsChanged = 8,
    ReservationTypeChanged = 9,
    BookingCancelled = 10,
    BookingReinstated = 11,
    BookingPublicWebsiteChanged = 12,
    ReservationMembershipTypeChanged = 13,

    BillItemProductChanged = 20,
    BillItemPriceChanged = 21,
    BillItemQuantityChanged = 22,
    BillItemProductTagChanged = 23,
    BillItemReservationChanged = 24,
    BillItemArchived = 25,
    BillItemRescheduled = 26,
    BillItemTaxRateChanged = 27,
    BillItemReinstated = 28,
    BillItemCancelled = 29,

    VoucherChanged = 40,
    VoucherExpiryChanged = 41,
    VoucherImported = 42,

    CustomerProfileNameChanged = 60,
    CustomerAddressChanged = 61,
    CustomerContactPreferencesChanged = 62,
    CustomerContactDetailsChanged = 63,
    CustomerEmergencyContactChanged = 64,
    CustomerBirthdayChanged = 65,
    CustomerPublicResultsPreferencesChanged = 66,
    CustomerGenderChanged = 67,

    BillDiscountApplied = 70,
    BillDiscountRemoved = 71,

    BillFeeCancelled = 76,
    BillFeeReinstated = 77,

    MembershipCreated = 80,
    MembershipAssignToCustomer = 81,
    MembershipTransferred = 82,
    MembershipExpiryChanged = 83,

    EventNameChanged = 90
}

export interface AuditTrailEntry {
    id: number;
    time: Date;
    auditEntryType: AuditEntryType;
    entityId: string;
    entityText: string;
    args: string[];
    userName: string;
}

export interface CustomFieldValue {
    customFieldName: string;
    type: CustomFieldType;
    value: string;
}

export interface EventRegistration {
    registrationId: string;
    customerId: string;
    bookingIds: string[];
    termsId: string
    counterSignatoryTermsId: string | null;
    registrationTime: Date;
    termsLastAgreed: Date;
    registrationTermsName: string;
    counterSigantoryFirstname: string;
    counterSigantoryLastname: string;
    resultEmailRequested: boolean;
    resultEmailSent: boolean;
    firstname: string;
    lastname: string;
    nickname: string | null;
    age: number | null;
    dateOfBirth: Date | null;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    addressLine4: string;
    town: string;
    county: string;
    countryId: number;
    postalCode: string;
    emailAddress: string;
    phoneNumber: string;
    photographUrl: string;
    emergencyContactName: string | null;
    emergencyContactNumber: string | null;
    hasSignature: boolean;
    clientIpAddress: string;
    isWebRegistration: boolean;
    kioskName: string | null;
    customFields: CustomFieldValue[];
    registeredByCustomerFirstname: string | null;
    registeredByCustomerLastname: string | null;
    registeredByCustomerEmail: string | null;
    registeredByCustomerPhone: string | null;
    registeredByParent: boolean;
    watchedVideo: boolean;
    customerCategories: EventRegistrationCategory[];
}

export interface EventTaskParameterVal {
    parameterType: ParameterType;
    value: string;
}

export interface EventTaskParameterTypeMap {
    [key: string]: EventTaskParameterVal;
}

export interface EventTask {
    id: string;
    eventId: string;
    taskDefinitionId: string;
    trigger: TaskTrigger;
    dueDate: Date | null;
    reminderDate: Date | null;
    state: TaskStateEnum;
    complete: boolean;
    parameters: EventTaskParameterTypeMap;
    specificRecipientAddress: string;
    recipentAddress: string;
}

export interface CustomerSearchResult {
    customerId: string;
    customerType: CustomerType;
    firstname: string;
    lastname: string;
    nickname: string | null;
    emailAddress: string;
    phoneNumber: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    addressLine4: string;
    town: string;
    county: string;
    countryId: number;
    postalCode: string;
    birthDay: number;
    birthMonth: number;
    birthYear: number;
    companyName: string;
    isOrganiser: boolean;
    gender: Gender;
    marketingPreference: MarketingPreference
    resultsPreference: MarketingPreference
    publicResultsConsent: boolean;
    emergencyContactName: string;
    emergencyContactNumber: string;
    tags: Tag[];
    notes: string;
    lastEvent: VenueDate | null;
    lastBooking: VenueDate | null;
    hasAcceptedLatestTerms: boolean;
    lastRegistrationId: string | null;
    lastRegistrationTerms: string;
    lastCurrentRegistrationId: string | null;
    hasActiveMembership: boolean;
    activeMembershipIds: string[]
}

export interface DiaryState {
    isLoading: boolean;
    isActive: boolean;
    date: Date;
    reservations: DiaryReservation[];
    openingTimes: OpeningTimes[];
    diaryNotes: { [key: string]: DiaryNote };
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    autoSaved: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
    isSavingDiaryNotes: boolean;
    diaryNoteSaveError: api.ApiError | null;
    customerSearchResults: CustomerSearchResult[];
    searchingCustomers: boolean;
    searchMessageKey: string;
    lastSearchTerm: string | null;
    activatingTask: boolean;
    taskActivationError: api.ApiError | null;
    taskActivationErrors: ValidationError[];
    scrollPosition: IScrollPosition;
    isSavingOpeningTimes: boolean;
    openingTimesSaveError: api.ApiError | null;
}

export interface BookingSearchResult {
    results: BookingSearchBooking[];
}

export interface BookingSearchBooking {
    bookingId: string;
    customerFirstName: string;
    customerLastName: string;
    customerCompanyName: string;
    customerPostCode: string;
    customerEmail: string;
    customerPhone: string;
    bookingStartTime: Date;
    resourceName: string;
    activityName: string;
    eventName: string;
    eventId: string;
    billId: string;
    billNumber: string;
    eventCancelled: boolean;
    bookingCancelled: boolean;
}

export enum DiaryActionTypes {
    SwitchDate = 'SWITCH_DATE',
    RequestDiaryReservations = 'REQUEST_DIARY_RESERVATIONS',
    ReceivedDiaryReservations = 'RECEIVE_DIARY_RESERVATIONS',
    LoadDiaryNote = 'LOAD_DIARY_NOTE',
    ReceivedDiaryNote = 'RECEIVE_DIARY_NOTE',
    SaveDiaryNote = 'SAVE_DIARY_NOTE',
    DiaryNoteSaved = 'DIARY_NOTE_SAVED',
    DiaryNoteSaveFailed = 'DIARY_NOTE_SAVE_FAILED',
    DiaryNoteChanged = 'DIARY_NOTE_CHANGED',
    EventChanged = 'EVENT_CHANGED',
    SearchCustomers = 'SEARCH_CUSTOMERS',
    SearchCustomerResponse = 'SEARCH_CUSTOMERS_RESPONSE',
    SetScrollPosition = "SET_SCROLL_POSITION",
    SaveOpeningTimes = 'SAVE_OPENING_TIMES',
    OpeningTimesSaved = 'OPENING_TIMES_SAVED',
    OpeningTimesSaveFailed = 'OPENING_TIMES_SAVE_FAILED',
    OpeningTimesChanged = 'OPENING_TIMES_CHANGED',
}

export interface SwitchDate {
    type: DiaryActionTypes.SwitchDate;
    date: Date;
}

export interface RequestEvents {
    type: DiaryActionTypes.RequestDiaryReservations;
    venueId: string;
    date: Date;
}

export interface ReceivedEvents {
    type: DiaryActionTypes.ReceivedDiaryReservations;
    reservations: DiaryReservation[];
    openingTimes: OpeningTimes[];
    error: api.ApiError | null;
}

export interface LoadDiaryNote {
    type: DiaryActionTypes.LoadDiaryNote;
    venueId: string;
    date: Date;
}

export interface ReceivedDiaryNote {
    type: DiaryActionTypes.ReceivedDiaryNote;
    venueId: string;
    date: Date;
    note: DiaryNote | null;
    error: api.ApiError | null;
}

export interface EventChanged {
    type: DiaryActionTypes.EventChanged;
    eventId: string;
    eventDate: Date | null;
}

export interface SaveDiaryNote {
    type: DiaryActionTypes.SaveDiaryNote;
    venueId: string;
    date: Date;
    noteText: string;
}

export interface DiaryNoteSaved {
    type: DiaryActionTypes.DiaryNoteSaved;
    venueId: string;
    date: Date;
}

export interface DiaryNoteSaveFailed {
    type: DiaryActionTypes.DiaryNoteSaveFailed;
    error: api.ApiError;
}

export interface DiaryNoteChanged {
    type: DiaryActionTypes.DiaryNoteChanged;
    venueId: string;
    date: Date;
}

export interface SearchCustomers {
    type: DiaryActionTypes.SearchCustomers;
    search: string;
}

export interface SearchCustomerResponse {
    type: DiaryActionTypes.SearchCustomerResponse,
    customers: CustomerSearchResult[];
    messageKey: string;
}

export interface SetScrollPosition {
    type: DiaryActionTypes.SetScrollPosition;
    top: number;
    left: number;
}

export interface SaveOpeningTimes {
    type: DiaryActionTypes.SaveOpeningTimes;
    venueId: string;
    date: Date;
    openingTimes: OpeningTimes;
}

export interface OpeningTimesSaved {
    type: DiaryActionTypes.OpeningTimesSaved;
    venueId: string;
    date: Date;
}

export interface OpeningTimesSaveFailed {
    type: DiaryActionTypes.OpeningTimesSaveFailed;
    error: api.ApiError;
}

export interface OpeningTimesChanged {
    type: DiaryActionTypes.OpeningTimesChanged;
    venueId: string;
    date: Date;
}

export const formatCustomerName = (customer: BookingCustomer | null, defaultText: string) => !customer || isNullOrEmpty(customer.customerId) ? defaultText : `${customer.firstname} ${customer.lastname}`;